import React from 'react'
import { Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

import styled from 'styled-components';

import { device } from '../styles/Breakpoints.js';

import GalleryThumbnailVideo from './GalleryThumbnailVideo.js'

const FeaturedGalleryThumbnailStyles = styled.div`
    width: calc(100% / 3);
    /* border: 1px solid blue; */
    padding: 0 12px 72px;
    text-align: center;
    font-family: "Alte Haas";
    font-weight: 700;
    text-transform: uppercase;
    color: #1B1B1B;
    @media ${device.$medium} {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 56px;
    }
    &:hover {
        .gatsby-image-wrapper {
            transform: scale(1.01);
        }
    }
    .cover-image {
        display: block;
        margin: 0 auto;
    }
    .internal-video {
        max-width: 100%;
        margin-bottom: 24px;
    }
    .vimeo-thumbnail {
        width: 100%;
        position: relative;
        &:after {
            content: '';
            display: block;
            padding-bottom: 75%;
        }
        iframe {
            position: absolute;
            left: 0;
            top: 0;
            max-height: 100%;
            max-width: 100%;
            pointer-events: none;
        }
    }
    .title {
        margin-bottom: 4px;
    }
    .gatsby-image-wrapper {
        margin-bottom: 24px;
        max-width: 640px;
        transition: .2s all ease-in-out;
    }
    .tag {
        font-size: 10px;
    }

    .video-placeholder {
        background: #efefef;
        padding-bottom: 55.88%;
        width: 100%;
        margin-bottom: 24px;
    }

    .image-placeholder {
        background: #efefef;
        padding-bottom: 129%;
        width: 100%;
        margin-bottom: 24px;
    }
`
export default function FeaturedGalleryThumbnail({data, thumbnailIndex}) {

    // console.log('data:', data)

    const gallery = data;
    
    let coverImage;
    let aspectRatio;

    if (gallery.images.length) {
        coverImage = gallery.cover_image ? gallery.cover_image.asset : gallery.images[0].asset;
        aspectRatio = coverImage.metadata.dimensions.aspectRatio;
    }
    else {
        coverImage = '';
        aspectRatio = 2;
    }

    

    

    const className = aspectRatio > 1 ? 'cover-image landscape' : 'cover-image portrait';
    const placeholderClassname = gallery.enable_cover_video ? 'video-placeholder' : 'image-placeholder';
    const showCoverVideo = typeof window !== 'undefined' && window.location.hostname === 'localhost' ? false : gallery.enable_cover_video;
    // const showCoverVideo = gallery.enable_cover_video;
    const showCoverImage = typeof window !== 'undefined' && window.location.hostname === 'localhost' ? false : !gallery.enable_cover_video;

    return (
        <FeaturedGalleryThumbnailStyles>
            <Link to={`/galleries/${data.slug.current}`} className={ className }>
                {
                    !showCoverVideo && !showCoverImage &&
                    <div className={placeholderClassname}></div>
                }
                {
                    showCoverVideo &&
                    <>
                        {
                            <GalleryThumbnailVideo data={gallery}/>
                        }

                        {
                            !gallery.cover_video &&
                            <div className="vimeo-thumbnail" dangerouslySetInnerHTML={{ __html: gallery.cover_video_vimeo }}></div>
                        }
                    </>
                
                }
                {
                    showCoverImage &&
                    <>
                        <GatsbyImage objectFit="contain" image={coverImage.gatsbyImageData} alt={ gallery.title } loading={thumbnailIndex > 3 ? 'lazy' : 'eager'}/>
                    </>
                }
                <div className="title" data-id={gallery.id}>
                    { gallery.title }
                </div>
                {
                    gallery.tags.map( tag => {
                        return (
                            <div className="tag" key={ tag.tag_name }>{ tag.tag_name }</div>
                        )
                    })
                }
            </Link>
        </FeaturedGalleryThumbnailStyles>
    )
}
