import React, { useState } from 'react';
import { Link } from 'gatsby';

import CornerIconMenu from './CornerIconMenu.js'; 

import styled from 'styled-components';

import { device } from '../styles/Breakpoints.js';

const hoverColor = (props) => {
  return `
      rgba( ${props.data.site_hover_color.rgb.r}, 
        ${props.data.site_hover_color.rgb.g},
        ${props.data.site_hover_color.rgb.b},
        ${props.data.site_hover_color.rgb.a})
      `
}


const CornerStyles = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-index__corner-icons);
    pointer-events: none;
      .corner-icon {
          width: 78px;
          /* height: 78px; */
          /* background-color: #eccece; */
          /* border: 1px solid #dadada; */
          position: absolute;
          pointer-events: all;
          transition: .2s all ease-in-out;
          &:hover {
            transform: scale(1.03);
          }
          img {
            width: 100%;
          }
          @media ${device.$medium} {
            width: 58px;
         }
          &--top_left {
              top: 24px;
              left: 24px;
              @media ${device.$medium} {
                   left: 12px;
            }
          }
          &--top_right {
              top: 24px;
              right: 24px;
              @media ${device.$medium} {
                   right: 12px;
            }
          }
          &--bottom_left {
              bottom: 24px;
              left: 24px;
              display: flex;
              align-items: flex-end;
              .gatsby-image-wrapper {
                display: flex;
              }
              @media ${device.$medium} {
                   left: 12px;
            }
          }
          &--bottom_right {
              bottom: 24px;
              right: 24px;
              @media ${device.$medium} {
                   right: 12px;
            }
          }
          &--center_left {
            top: 50%;
            left: 24px;
            transform: translateY(-50%);
            @media ${device.$medium} {
                  left: 12px;
            }

            &:hover {
              transform: scale(1.03) translateY(-50%);
            }
          }
      }
      .about-link {
            pointer-events: all;
            position: absolute;
            right: 0;
            width: fit-content;
            transform: rotate(90deg) translateY(-50%);
            transform-origin: center;
            top: 50%;
            font-weight: 700;
            transition: .2s all ease-in-out;
            &:hover {
              color: ${props => hoverColor(props)};
            }
            @media ${device.$medium} {
                right: -13px;
            }
          }
    `

const cornerIcon = (position, data, handleFilterClick, showMenu, clickHandler, filterValues) => {


  const className = `corner-icon corner-icon--${position}`;
  const url = data[`corner_icon__${position}_url`];
  const image = data[`corner_icon__${position}_image`];

  const isTopLeft = position === 'top_left';

  const thisClickHandler = (event) => {
    if (isTopLeft) {
      clickHandler(event)
    }
  }

  if (url && url.includes('https://')) {
    return (
      <>
      <a href={url} className={className} onClick={(event) => thisClickHandler(event)}>
        {
          image && 
          <img src={image.asset.gatsbyImageData.images.fallback.src} loading="eager" alt={ position + ' corner icon' } />
          // <GatsbyImage image={image.asset.gatsbyImageData} objectPosition={"center"} loading={"eager"} width={78} objectFit={"contain"} alt={ position + ' corner icon' }/>
        }
      </a>
      {
        isTopLeft && data.corner_icon_filter_options &&
        <CornerIconMenu data={data} handleFilterClick={handleFilterClick} filterValues={filterValues} clickHandler={clickHandler} showMenu={showMenu}/>
      }
      </>
    )
  }

  else if (url && !url.includes('https://')){
    return (
      <>
      <Link to={url} className={className} onClick={(event) => thisClickHandler(event)}>
        {
          image &&
          <img src={image.asset.gatsbyImageData.images.fallback.src} loading="eager" alt={ position + ' corner icon' } />
          // <GatsbyImage image={image.asset.gatsbyImageData} objectPosition={"center"} loading={"eager"} width={78} objectFit={"contain"} alt={ position + ' corner icon' }/>
        }
      </Link>
      {
        isTopLeft && data.corner_icon_filter_options &&
        <CornerIconMenu data={data} handleFilterClick={handleFilterClick} filterValues={filterValues} clickHandler={clickHandler} showMenu={showMenu}/>
      }
      </>
    )
  }
  else if ( !url ) {
    return (
      <>
        <div className={className} onClick={(event) => thisClickHandler(event)}>
          {
            image &&
            <img src={image.asset.gatsbyImageData.images.fallback.src} loading="eager" alt={ position + ' corner icon' } />
          }
        </div>
        {
          isTopLeft && data.corner_icon_filter_options &&
          <CornerIconMenu data={data} handleFilterClick={handleFilterClick} filterValues={filterValues} clickHandler={clickHandler} showMenu={showMenu}/>
        }
      </>
    )
  }
}
export default function CornerIcons(props) {

  const data = props.data;
  const siteSettings = props.siteSettings;
  const handleFilterClick = props.handleFilterClick;
  const filterValues = props.filterValues;
  const [showMenu, setShowMenu] = useState(false);

  const classNames = showMenu ? 'menu-active' : '';

  const clickHandler = (event) => {
    console.log('event:', event)
    if (data.corner_icon_filter_options.length) {
      if (event) {
        event.preventDefault();
      }
      setShowMenu(!showMenu);
    }
  }

    return (
        <CornerStyles data={siteSettings} className={classNames}>
            { cornerIcon('top_left', data, handleFilterClick, showMenu, clickHandler, filterValues) }
            { cornerIcon('top_right', data) }
            { cornerIcon('bottom_left', data) }
            { cornerIcon('bottom_right', data) }
            { cornerIcon('center_left', data) }

            <Link to={'/about-us'} className="about-link">
              ABOUT US
            </Link>
            {/* TODO -  ^^ Swap for sanity field */}
        </CornerStyles>
    )
}
