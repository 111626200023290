import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from "react-helmet";

import {JsonLD} from '../components/JsonLD'

import styled from 'styled-components';

import CornerIcons from '../components/CornerIcons';
import IndexFeaturedGalleries from '../components/IndexFeaturedGalleries';
import Header from '../components/Header';
import Cursor from '../components/Cursor';




const IndexStyles = styled.div`
      main {
        padding-top: var(--header-height--desktop);
        padding-bottom: var(--header-height--desktop);
      }
`

export default function HomePage({ data }) {

    const cornerIcondata = data.cornerIconData.nodes[0];
    const homePageSections = data.homePageData.nodes[0].content;
    const siteSettings = data.siteSettings.nodes[0];

    const [filterValues, setFilterValues] = useState([]);

    const handleFilterClick = (value) => {
     const filterArray = [...filterValues]
     if (filterArray.indexOf(value) > -1) {
      filterArray.splice(filterArray.indexOf(value), 1)
     }
     else {
      filterArray.push(value);
     }
     
     setFilterValues(filterArray);
    }

    return (
      <>
      <Helmet title="Mimi Casting">
        <JsonLD>
          {{   "@context":"https://schema.org",
              "@graph":[
              {
                  "@type":"Organization",
                  "@id":"https://mimi-casting.world",
                  "name":"Mimi Casting",
                  "url":"https://mimi-casting.world",
                  "sameAs": "https://www.instagram.com/mimi.casting"
              },
              {
                  "@type":"WebSite",
                  "@id":"https://mimi-casting.world",
                  "url":"https://mimi-casting.world",
                  "name":"Mimi Casting",
                  "description":"Mimi is a casting direction & scouting service based in Sydney, Australia. Mimi Casting aims to work globally to cast, curate and pair individual talent to their clients needs."
              }
            ]
          }}
        </JsonLD>
      </Helmet>
        <IndexStyles className="index-page">
          <Header data={siteSettings} />
          <CornerIcons data={cornerIcondata} siteSettings={siteSettings} handleFilterClick={handleFilterClick} filterValues={filterValues}/>
          <Cursor 
            staticText={siteSettings.cursor_static}
            staticTextColor={siteSettings.cursor_static_color.hex}
            cursorSparkle={siteSettings.cursor_sparkle}
          />
            <main>
              {
                homePageSections.map( section => {
                  if (section._type === "index_section_featured") {
                    return ( 
                      <IndexFeaturedGalleries data={ section } siteSettings={siteSettings} key={ section._key } handleFilterClick={handleFilterClick} filterValues={filterValues}/>
                    )
                  }
                })
              }
            </main>
        </IndexStyles>
      </>
    )
}

export const query = graphql`
query {
    cornerIconData: allSanityCornerIcons {
        nodes {
          corner_icon__top_left_url
          corner_icon__top_right_url
          corner_icon__bottom_left_url
          corner_icon__bottom_right_url
          corner_icon__center_left_url
          corner_icon__top_left_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
          corner_icon__top_right_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
          corner_icon__bottom_left_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
          corner_icon__bottom_right_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
          corner_icon__center_left_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
          corner_icon_filter_options
        }
    }
    homePageData: allSanityHomepage {
    nodes {
      content {
        _key
        ... on SanityIndexSectionFeatured {
          enable_section
          featured_galleries {
            id
            title
            enable_cover_video
            feature_video {
              video_file {
                asset {
                  url
                  mimeType
                }
              }
            }
            cover_video {
              mux_video {
                asset {
                  playbackId
                }
              }
              video_file {
                asset {
                  url
                }
              }
            }
            cover_video_vimeo
            cover_image {
              asset {
                gatsbyImageData(width: 640, layout: CONSTRAINED, placeholder: BLURRED)
                metadata {
                  dimensions {
                    aspectRatio
                  }
                }
              }
            }
            images {
              asset {
                gatsbyImageData(width: 255, layout: CONSTRAINED, placeholder: BLURRED)
                metadata {
                  dimensions {
                    aspectRatio
                  }
                }
              }
            }
            slug {
              current
            }
            tags {
              tag_name
            }
          }
          _type
          _key
        }
      }
    }
  }
  siteSettings: allSanitySiteSettings {
    nodes {
      logo {
        asset {
          extension
          gatsbyImageData(width: 300, layout: CONSTRAINED)
          url
        }
      }
      site_hover_color {
        rgb {
          r
          g
          b
          a
        }
      }
      cursor_sparkle
      cursor_static
      cursor_static_color {
        hex
      }
    }
  }
}
`