import React from 'react'


import styled from 'styled-components';

import { device } from '../styles/Breakpoints.js';
import FeaturedGalleryThumbnail from './FeaturedGalleryThumbnail.js';

const FeaturedGalleriesStyles = styled.div`

    .active-filters {
        position: sticky;
        top: var(--header-height--desktop);
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        margin-bottom: 32px;

        @media ${device.$medium} {
            position: relative;
            top: initial;
        }

        button {
            &:not(:last-child) {
                margin-right: 8px;
            }
        }
    }

    .active-filter__header {
        font-weight: 700;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        margin-bottom: 12px;
    }

    .grid {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        /* border: 1px solid orange; */
        @media ${device.$medium} {
        }
    }
    `
export default function IndexFeaturedGalleries({data, handleFilterClick, filterValues}) {


    data.featured_galleries.forEach(gallery => {
        gallery.tagsArray = gallery.tags.map(tag => tag.tag_name)
    });

    const allGalleries = data.featured_galleries;

    const filteredGalleries = allGalleries.filter(gallery => gallery.tagsArray.some(tag => filterValues.indexOf(tag) >= 0));

    const galleriesToShow = filteredGalleries.length ? filteredGalleries : allGalleries;

    return (
        <FeaturedGalleriesStyles className="container--clear-icons">
            {
                filterValues.length ?
                <div className="active-filters">
                    <div className="active-filter__header">Filtered By:</div>
                    {
                        filterValues.map(filterValue => {
                            return (
                                <button key={filterValue} className="btn--none btn--styled" onClick={() => handleFilterClick(filterValue)}>{filterValue}</button>
                            )
                        })
                    }
                    
                </div>
                : null
            }
            <div className="grid">
                {
                    galleriesToShow.map((gallery, index) => {
                        return (
                            <FeaturedGalleryThumbnail data={gallery} key={ gallery.id } thumbnailIndex={index}/>
                        )
                    })
                }
            </div>
        </FeaturedGalleriesStyles>
    )
}
