import React from 'react'
import styled from 'styled-components';

import { device } from '../styles/Breakpoints.js';

const GalleryThumbnailVideoStyles = styled.div`
`
export default function FeaturedGalleryThumbnail({data, thumbnailIndex}) {
    

    return (
        <GalleryThumbnailVideoStyles>
            {
                data.cover_video.mux_video &&
                <img className="mux__thumbnail" src={`https://image.mux.com/${data.cover_video.mux_video?.asset.playbackId}/animated.gif?fps=24&end=10&height=200`} loading="lazy" />
            }
            {
                !data.cover_video.mux_video &&
                <video playsInline autoPlay muted loop id="" className="internal-video">
                    <source src={data.cover_video.video_file.asset.url} type={data.cover_video.video_file.asset.mimeType} ></source>
                </video>
            }
                
            
        </GalleryThumbnailVideoStyles>
    )
} 