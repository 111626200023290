import React from 'react'
import { Link } from 'gatsby';
// import { GatsbyImage } from "gatsby-plugin-image";

import styled from 'styled-components';

import { device } from '../styles/Breakpoints.js';

const HeaderStyles = styled.header`
   width: 100%;
   height: var(--header-height--desktop);
   display: flex;
   justify-content: center;
   align-items: flex-start;
   position: fixed;
   top: 0;
   left: 126px;
   z-index: var(--z-index__header);
   padding: 24px 126px;
   width: calc(100% - 252px); //Minus 2 x icons and their padding
   text-align: center;
   a {
       height: 100%;
       max-width: 150px;
       /* .gatsby-image-wrapper {
           height: 100%;
       } */
       img {
           width: 100%;
           max-height: 100%;
       }
   }
   @media ${device.$medium} {
        height: var(--header-height--mobile);
        padding: 24px 48px 0;
        width: calc(100% - 144px);
        left: 72px;
        a {
            height: auto;
            width: 100%;
        }
    }
`

export default function Header({data}) {

//   console.log('Header Data', data)

    let logoSrc

    if (data.logo) {
        const extension = data.logo.asset.extension
        if (extension === "png") {
            logoSrc = data.logo.asset.gatsbyImageData.images.fallback.src
        }
        else if ( extension === "gif" ){
            logoSrc = data.logo.asset.url
        }
    }

    return (
        <HeaderStyles>
            <Link to={'/'}>
                {
                    data.logo &&
                    <>
                    <img src={logoSrc} loading="eager" alt="Mimi Casting Logo" />
                    {/* <GatsbyImage image={data.logo.asset.gatsbyImageData} objectPosition={"center"} loading={"eager"} width={85} objectFit={"contain"} alt="Mimi Casting"/> */}
                    <h1 className="sr-only">Mimi Casting</h1>
                    </>
                    
                }
                {
                    !data.logo &&
                    <h1>MIMI CASTING</h1>
                }
            </Link>
        </HeaderStyles>
    )
}
