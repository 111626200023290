import React from 'react';

import styled from 'styled-components';

import { device } from '../styles/Breakpoints.js';

const CornerIconMenuStyles = styled.div`
    position: absolute;
    left: 24px;
    top: 94px;
    width: 78px;
    min-height: 0;
    max-height: 0;
    pointer-events: none;
    z-index: calc( var(--z-index__corner-icons) - 1);

    .filter-by-label {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 8px;
    }

    .mobile-mask {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        

        .menu-active & {
            @media ${device.$medium} {
                background-color: rgba(0,0,0,.3);
            }
        }
        
    }

    .active-filter-icon {
        display: none;
        font-size: 12px;
    }

    button {
        position: relative;
        display: block;
        transform: translateX(0);
        animation-duration: 300ms;
        animation-delay: calc(var(--animation-order) * 100ms);
        animation-fill-mode: both;
        animation-timing-function: ease-in-out;
        animation-name: animateOut;
        @media ${device.$medium} {
            font-size: 24px;
            animation-name: animateOutMobile;
        }
        &:not(:last-child) {
            margin-bottom: 8px;
            @media ${device.$medium} {
                margin-bottom: 12px;
            }
        }

        &.filter-active {

            &:after {
                background-color: rgba(255, 99, 212, .95);
            }

            .active-filter-icon {
                display: inline-block;
                margin-right: 8px;
            }
        }
    }


    .menu-active & {
        max-height: 100%;
        visibility: visible;
        opacity: 1;
        pointer-events: all;

        button {
            animation-name: animateIn;
            @media ${device.$medium} {
                animation-name: animateInMobile;
            }
        }
    }

    @keyframes animateIn {
        0% {
            opacity: 0;
            transform: translateY(-100%);
        }
        50% {
            opacity: 0;
        }
        
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    @keyframes animateOut {
        0% {
            opacity: 1;
            transform: translateY(0);
        }
        50% {
            opacity: 0;
        }
        
        100% {
            opacity: 0;
            transform: translateY(-100%);
        }
    }
    @keyframes animateInMobile {
        0% {
            opacity: 0;
            transform: translateX(-100%);
        }
        50% {
            opacity: 0;
        }
        
        100% {
            opacity: 1;
            transform: translateX(0);
            
        }
    }
    @keyframes animateOutMobile {
        0% {
            opacity: 1;
            transform: translateX(0);
        }
        50% {
            opacity: 0;
        }
        
        100% {
            opacity: 0;
            transform: translateX(-100%);
            
        }
    }
`


export default function CornerIconMenu(props) {
    const {data, handleFilterClick, showMenu, filterValues, clickHandler} = props;

    const classNames = showMenu ? 'nav-wrapper active' : 'nav-wrapper';

    const thisClickHandler = (filterItem) => {
        handleFilterClick(filterItem);
        clickHandler();
    }

    const setCustomProperty = (index) => {
        return {
            '--animation-order': index
        }
    }

    return (
        <CornerIconMenuStyles className={classNames}>
            {
                showMenu &&
                <>
                    <div className="mobile-mask" onClick={() => clickHandler()} onKeyDown={() => clickHandler()} role="button" tabIndex={0}>
                        <span className="visually-hidden">Close filters</span>
                    </div>
                    <div className="filter-by-label">Filter By:</div>
                </>
            }
            
            {
                data.corner_icon_filter_options.map( (filterItem, index) => {
                    const setCustomProperties = setCustomProperty(index);

                    const buttonClassNames = filterValues.includes(filterItem) ? 'filter-active btn--none btn--styled' : 'btn--none btn--styled';
                    return (
                        <button
                            className={buttonClassNames}
                            key={ filterItem } 
                            style={ setCustomProperties }
                            onClick={() => thisClickHandler(filterItem)}
                        >
                            <span className="active-filter-icon">&#x2715;</span>
                            { filterItem }
                        </button>
                    )
                })
            }
        </CornerIconMenuStyles>
    )
}
